const ACTION_CONSTANTS = {
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_USER_AUTHENTICATION: "SET_USER_AUTHENTICATION",
  SET_USER_TOKEN: "SET_USER_TOKEN",
  SET_USER_ROLES: "SET_USER_ROLES",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  ERROR: "ERROR",
  IS_LOADING: "IS_LOADING",
  SET_USER_AUTHORIZATION: "SET_USER_AUTHORIZATION",
  SET_REDACTION_INFO:"SET_REDACTION_INFO",
  SET_IS_PAGE_LEFT_OFF:"SET_IS_PAGE_LEFT_OFF",
  SET_SECTIONS:"SET_SECTIONS",
  //SET_PAGE_FLAGS: "SET_PAGE_FLAGS",
  SET_DOCUMENT_LIST: "SET_DOCUMENT_LIST",
  SET_KEYWORDS: "SET_KEYWORDS",
  SET_REQUEST_INFO: "SET_REQUEST_INFO",
  SET_REQUEST_STATUS: "SET_REQUEST_STATUS",
  SET_REDACTION_LAYERS: "SET_REDACTION_LAYERS",
  SET_CURRENT_LAYER: "SET_CURRENT_LAYER",
  INC_REDACTION_LAYER: "INC_REDACTION_LAYER",
  SET_REQUEST_NUMBER:"SET_REQUEST_NUMBER",
  SET_DELETED_PAGES: "SET_DELETED_PAGES",
  SET_PUBLIC_BODIES: "SET_PUBLIC_BODIES",
  FOI_PERSONAL_SECTIONS: "FOI_PERSONAL_SECTIONS",
  FOI_PERSONAL_PEOPLE: "FOI_PERSONAL_PEOPLE",
  FOI_PERSONAL_FILETYPES: "FOI_PERSONAL_FILETYPES",
  FOI_PERSONAL_VOLUMES: "FOI_PERSONAL_VOLUMES",
};

export default ACTION_CONSTANTS;
